import React, { useState, useEffect } from "react"
import styled from 'styled-components'
import useDarkMode from 'use-dark-mode';
import { useStaticQuery, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import * as tokens from "../tokens/tokens"


const $Portrait = styled.div`
    margin-top: ${tokens.space.md};
    border-radius: ${tokens.radius.md};
    overflow: hidden;
    margin-bottom: ${tokens.space.md};
    
    .gatsby-image-wrapper > div {
        padding-bottom: 75% !important; /* your aspect ratio */
    }
`

const Portrait = ( {data} ) => {
    
    const isDark = useDarkMode().value;

    return(
        <StaticQuery 
            query={graphql`
                query PortraitQuery {
                    imageLight: file(relativePath: {eq: "filip-pizl_light.jpg"}) {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    imageDark: file(relativePath: {eq: "filip-pizl_dark.jpg"}) {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            `}
            render={
                data => (
                    <$Portrait>
                        <Img
                            fluid={
                                isDark
                                    ? data.imageDark.childImageSharp.fluid
                                    : data.imageLight.childImageSharp.fluid
                                }
                            alt="Filip Pižl"
                        />
                    </$Portrait>
                )
            }

        />
    );
};

export default Portrait;