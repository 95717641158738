/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useLayoutEffect } from 'react';
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import * as DesignSystem from "./Design-system"
import { ThemeProvider, ThemeContext } from "../tokens/theme"
import { MDXProvider } from "@mdx-js/react"
import GlobalStyle from "../tokens/global-style"
import "./fonts.css"


import Header from "./Header"
import Footer from "./Footer"
import Portrait from "./Portrait"
import * as Dates from "./Dates"

const Layout = ({ children }) => {

  const [isClient, setIsClient] = useState(false);
  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          url
          description
          linkedin
          xing
          instagram
          email
          menuLinks{
            name
            link
          }
        }
      }
    }
  `)
   
  const siteMetadata = data.site.siteMetadata;

  useLayoutEffect(() => {
    setIsClient(true);
  },[]);

  return (
      
      <ThemeProvider >
        {isClient &&

          <div className="siteWrapper">
        
          <ThemeContext.Consumer>
            {context => <GlobalStyle theme={ context.colorTheme } /> }
          </ThemeContext.Consumer>
          
          <Header
            siteTitle={siteMetadata.title}
            siteUrl={siteMetadata.url}
            menuLinks={siteMetadata.menuLinks}
            description={siteMetadata.description}
          />
          <MDXProvider
            components={{
              h1: DesignSystem.H1,
              h2: DesignSystem.H2,
              h3: DesignSystem.H3,
              h4: DesignSystem.Meta,
              p: DesignSystem.Body,
              blockquote: DesignSystem.Lead,
              hr: DesignSystem.Hr,
              Portrait: Portrait,
              Age: Dates.Age,
              Since: Dates.Since,
            }}>
              <main>
                {children}
              </main>
          </MDXProvider>

          <Footer 
            siteTitle={siteMetadata.title}
            siteUrl={siteMetadata.url}
            linkedin={siteMetadata.linkedin}
            xing={siteMetadata.xing}
            instagram={siteMetadata.instagram}
            email={siteMetadata.email}
          />

        </div>  
        
        }
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}


export default Layout
