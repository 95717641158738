import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import styled, { withTheme, ThemeProvider } from 'styled-components'
import { ThemeContext } from "../tokens/theme"
import * as tokens from "../tokens/tokens"
import DarkModeToggle from "./DarkmodeToggle"

const $Container = styled.div`
  margin: ${tokens.space.md};
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props=>props.theme.colorBorder}; 

  @media (min-width: 640px) {
    margin-top: ${tokens.space.md};
  }
`

// is hidden now
const $Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${tokens.space.md};

  color: ${props => props.theme.colorTextPrimary};
  font-family: ${tokens.typography.fontFamily.body};
  line-height: ${tokens.typography.lineHeight.md};
  font-size: ${tokens.typography.fontSize.xs};
    
  & a {
      font-family: ${tokens.typography.fontFamily.bold};
      line-height: ${tokens.typography.lineHeight.md};
      text-decoration: none;
      color: ${props => props.theme.colorTextPrimary};
      margin-right: ${tokens.space.sm};
  }

  & a:hover{
      margin-right: calc( ${tokens.space.sm} - ${tokens.space.xxs} );
  }

`

const $Avatar = styled.img`
  float: left;
  height: ${tokens.space.lg};
  border-radius: ${tokens.radius.circle};
  margin-right: ${tokens.space.sm};
`

const $Menu =styled.ul`
  list-style-type: none;
  margin-top: ${tokens.space.md};

  & li {
    display: inline-block;
    margin: 0;
    margin-right: ${tokens.space.md};
    margin-bottom: -1px;
  }
  
  & a{
    display: inline-block;
    padding-bottom: ${tokens.space.sm};
    font-size: ${tokens.typography.fontSize.xs};
    font-family: ${tokens.typography.fontFamily.bold};
    line-height: ${tokens.typography.lineHeight.md};
    text-decoration: none;
    color: ${props=>props.theme.colorTextSecondary}; 

    height: ${tokens.space.lg};
  }
  
  & a:hover{
    background: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    
    color: ${props=>props.theme.colorTextPrimary}; 
    border-bottom: 1px solid ${props=>props.theme.colorTextPrimary}; 
    text-decoration: none;
  }
  
  
  & a.active{
    font-family: ${tokens.typography.fontFamily.bold};
    border-bottom: 1px solid ${props=>props.theme.colorTextPrimary}; 
    text-decoration: none;
    color: ${props=>props.theme.colorTextPrimary}; 
  }
  
`

const Header = ( props ) => {

  const data = props.data;

    return(
      <ThemeContext.Consumer>
      { context => (

        <$Container theme={context.colorTheme}>

          {/* <$Title theme={context.colorTheme}>
              <div>
                <$Avatar src={avatar} />
                <Link to="/">{props.siteTitle}</Link>
                — Product Designer
              </div>
              <DarkModeToggle/>
          </$Title> */}

            <$Menu theme={context.colorTheme}>
              {props.menuLinks.map( link => (
                <li><Link activeClassName="active" to={link.link} >{link.name}</Link></li>
              ))}
            </$Menu>

              <DarkModeToggle/>
        </$Container>

      )}
      </ThemeContext.Consumer>
    )

}

// export const query = graphql`

// query Query {
//     file(relativePath: {eq: "avatar.jpg"}) {
//       childImageSharp {
//           fixed(width: 24, height: 24) {
//             ...GatsbyImageSharpFixed
//           }
//         }
//     }
// }
// `

export default Header;
