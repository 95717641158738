
export const neutrals = {
    black: '#0D0D0D',
    gray90: '#1F1F1F',
    gray80: '#404040',
    gray60: '#666666',
    gray40: '#8C8C8C',
    gray20: '#CCCCCC',
    gray10: '#E6E6E6', 
    white:' #F2F2F2',
}

export const brand = {
    primary: 'red',
    secondary: 'yellow',
}

export const shadows = {
    boxShadowSm: "0px 1px 2px rgba(0, 0, 0, 0.12), 0px 0px 4px rgba(0, 0, 0, 0.04)",
    boxShadowMd: "0px 2px 8px rgba(0, 0, 0, 0.12), 0px 0px 16px rgba(0, 0, 0, 0.04)",
    boxShadowLg: "0px 2px 8px rgba(0, 0, 0, 0.12), 0px 0px 16px rgba(0, 0, 0, 0.04)",
}

export const typography = {
    fontFamily: {
        body: "'Open Sauce Sans Regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
        heading: "'Open Sauce Sans SemiBold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
        bold: "'Open Sauce Sans SemiBold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
       
        // body: "'HankenGrotesk-Regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
        // heading: "'HKGroteskWide-Regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
        // bold: "'HKGroteskWide-Bold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
        
        // depricated:
        // wide: "'HKGroteskWide-Regular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",

        // body: "'open_sauce_sansregular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;",
        // heading: "'SangBleuKingdom-Regular', Georgia, 'Times New Roman', Times, serif;",
        // strong: "'SangBleuKingdom-Medium', Georgia, 'Times New Roman', Times, serif;",
    },
    fontSize: {
        xs: "14px",
        sm:  "15px", // not used now, meant for mobile copy
        md: "16px",
        // maybe introduce an 18px font here at some point
        lg: "24px",
        xl: "36px",
        xxl: "48px", 
    },
    lineHeight: {
        xs:  "1.1",
        sm:  "1.25",
        md: "1.4",
        lg: "2",
    },
    fontWeight: {
        normal: '400',
        semibold: '600',
        bold: '800',
    },
    letterSpacing:{
        body: '-0.02em',
        display: '-0.04em'
    },
}

export const space = {
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '40px',
    xxxl: '64px', 
}
  
export const radius = {
    sm: '4px',
    md: '8px',
    lg: '16px',
    circle: '50%',
}
