import React from 'react';
import styled, { withTheme, ThemeProvider } from 'styled-components'
import { ThemeContext } from "../tokens/theme"
import * as DesignSystem from "./Design-system"
import * as tokens from "../tokens/tokens"

const $Container = styled.div`
  font-size: ${tokens.typography.fontSize.xs};
  line-height: ${tokens.typography.lineHeight.md};
  text-align: center;

  padding: ${tokens.space.md};
  margin: ${tokens.space.xxxl} ${tokens.space.md};
  border-radius: ${tokens.radius.md};
  background: ${props => props.theme.colorSurface};
  color: ${props => props.theme.colorTextSecondary};
`

const $Social = styled.ul`
  color: ${props => props.theme.colorTextSecondary};
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  padding: ${tokens.space.lg} ;
  border-bottom: 1px solid ${props => props.theme.colorBorder};
  margin-bottom: ${tokens.space.md};

  & > li{
    display: block;
    flex: 0 1 auto; /* Default */
    list-style-type: none;
  }

  & > li > a {
    font-family: ${tokens.typography.fontFamily.bold};
    text-decoration: none;
  }
  

`

const $Copyright = styled.div`
  color: ${props => props.theme.colorTextSecondary};
`

const Footer = (props) => {

    return (
        <ThemeContext.Consumer>
            { context => (

            <$Container theme={context.colorTheme}>

              Get in touch 

              <$Social theme={context.colorTheme}>
                <li><a href={props.instagram}>Instagram</a></li>
                <li><a href={props.linkedin}>LinkedIn</a></li>
                <li><a href={props.xing}>Xing</a></li>
                <li><a href={`mailto: ${props.email}`}  onClick={props.email}>Email</a></li>
              </$Social>

              <$Copyright>
                © {new Date().getFullYear()} Filip Pižl — All rights reserved
              </$Copyright>

            </$Container>

            )}
      </ThemeContext.Consumer>
    );
};

export default Footer;