import React from "react"
import useDarkMode from 'use-dark-mode';
import * as baseTokens from "./tokens";

export const themes = {
    dark: {
        colorTextPrimary: baseTokens.neutrals.white,
        colorTextSecondary: baseTokens.neutrals.gray40,

        colorBorder: baseTokens.neutrals.gray80,
        colorSurface: baseTokens.neutrals.gray90,
        colorBackground: baseTokens.neutrals.black,
    },
    light: {
        colorTextPrimary: baseTokens.neutrals.black,
        colorTextSecondary: baseTokens.neutrals.gray60,

        colorBorder: baseTokens.neutrals.gray20,
        colorSurface: baseTokens.neutrals.gray10,
        colorBackground: baseTokens.neutrals.white,
    },
};

export const tokens = {
    // maybe just use the tokens.js file?
    fontFamilyBody: baseTokens.typography.fontFamily.bodyFamily,
}

export const ThemeContext = React.createContext();

export const ThemeProvider = ({ children }) => {
    
    var colorTheme = themes.light;

    const setColorTheme = ( isDark ) => {
        if ( !isDark ){
            colorTheme = themes.light;
      }else{
          colorTheme = themes.dark;
      }
    }

    const darkMode = useDarkMode();

    const darkModeConfig = {
        onChange: setColorTheme(darkMode.value),
    };


    return (
      <ThemeContext.Provider value={{ colorTheme, setColorTheme }}>
        {children}
      </ThemeContext.Provider>
    )
  }
