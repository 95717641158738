import { createGlobalStyle, ThemeProvider } from 'styled-components'
import reset from 'styled-reset'
import * as tokens from "./tokens";


const GlobalStyle = createGlobalStyle`
  ${reset}

  html, body {
    font-family: ${tokens.typography.fontFamily.body};
    font-size: ${tokens.typography.fontSize.md};
    color: ${props => props.theme.colorTextPrimary};
    background: ${props => props.theme.colorBackground};
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
  }

  div.siteWrapper{
    border: 0px solid ${props => props.theme.colorBorder};

    margin-bottom: ${tokens.space.md};
    border-radius: ${tokens.radius.md};
    background: ${props => props.theme.colorBackground};
  }

  main{
    padding: 0 16px;
  }

  p{
    font-family: ${tokens.typography.fontFamily.body};
    font-size: ${tokens.typography.fontSize.xs};
    line-height: ${tokens.typography.lineHeight.md};
    color: ${props => props.theme.colorTextSecondary};
  }

  a{
    color: ${props => props.theme.colorTextPrimary};
  }

  a:hover{
    background: ${props => props.theme.colorSurface};
    text-decoration: none;
    padding-left: ${tokens.space.xxs};
    padding-right: ${tokens.space.xxs};
    margin-right: -${tokens.space.xxs};
    margin-left: -${tokens.space.xxs};
    border-radius: ${tokens.radius.sm};
  }
`

export default GlobalStyle;
