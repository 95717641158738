import React from 'react'
import styled from 'styled-components'
import { ThemeContext } from "../tokens/theme"
import * as tokens from "../tokens/tokens";

const $Hero = styled.h1`
    font-family: ${tokens.typography.fontFamily.heading};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.lg};
    line-height: ${tokens.typography.lineHeight.sm};
    margin: ${tokens.space.md} 0;
    letter-spacing: ${tokens.typography.letterSpacing.display};
    color: ${props => props.theme.colorTextSecondary};
    
    & span {
        color: ${props => props.theme.colorTextPrimary};
    }

    @media (min-width: 640px) {
        font-size: ${tokens.typography.fontSize.xl};
    }

   
`
export const Hero = (props) => {
    return(
        <ThemeContext.Consumer>
            {context => (
                <$Hero theme={context.colorTheme}>{props.children}</$Hero>
            )}
        </ThemeContext.Consumer>
    );
}

const $h1 = styled.h1`
    font-family: ${tokens.typography.fontFamily.heading};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.xl};
    line-height: ${tokens.typography.lineHeight.xs};
    margin: ${tokens.space.md} 0;
    letter-spacing: ${tokens.typography.letterSpacing.display};
    color: ${props => props.theme.colorTextPrimary};
`
export const H1 = (props) => {
    return(
        <ThemeContext.Consumer>
            {context => (
                <$h1 theme={context.colorTheme}>{props.children}</$h1>
            )}
        </ThemeContext.Consumer>
    );
}


const $h2 = styled.h2`
    font-family: ${tokens.typography.fontFamily.body};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.xs};
    line-height: ${tokens.typography.lineHeight.md};
    color: ${props => props.theme.colorTextSecondary};
    margin-top: ${tokens.space.sm};

`
export const H2 = (props) => {
    return(
        <ThemeContext.Consumer>
        {context => (
            <$h2 theme={context.colorTheme}>{props.children}</$h2>
        )}
        </ThemeContext.Consumer>
    );
}


const $h3 = styled.h3`
    /* border: 1px solid red; */
    font-family: ${tokens.typography.fontFamily.heading};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.md};
    line-height: ${tokens.typography.lineHeight.xs};
    letter-spacing: ${tokens.typography.letterSpacing.body};
    color: ${props => props.theme.colorTextPrimary};
    padding-top: ${tokens.space.xxl};
    
    & span {
        color: ${props => props.theme.colorTextSecondary};
    }
    
     span > span{
        color: ${props => props.theme.colorTextPrimary};
    }

`
export const H3 = (props) => {
    return(
        <ThemeContext.Consumer>
        {context => (
            <$h3 theme={context.colorTheme}>{props.children}</$h3>
        )}
        </ThemeContext.Consumer>
    );
}


const $Meta = styled.p`
    font-family: ${tokens.typography.fontFamily.body};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.xs};
    line-height: ${tokens.typography.lineHeight.md};
    color: ${props => props.theme.colorTextPrimary};
    padding: ${tokens.space.sm} 0;
`
export const Meta = (props) => {
    return(
        <ThemeContext.Consumer>
        {context => (
            <$Meta theme={context.colorTheme}>{props.children}</$Meta>
        )}
        </ThemeContext.Consumer>
    );
}



const $Lead = styled.p`
    margin-bottom: ${tokens.space.sm};

    & > div{
        font-family: ${tokens.typography.fontFamily.heading};
        color: ${props => props.theme.colorTextSecondary};
        /* color: ${props => props.theme.colorTextPrimary}; */
        text-rendering: optimizeLegibility;
        font-size: ${tokens.typography.fontSize.md};
        letter-spacing: ${tokens.typography.letterSpacing.heading};
        line-height: ${tokens.typography.lineHeight.md};
    }
    
    & > div > strong{
        color: ${props => props.theme.colorTextPrimary};
    }
    
    @media (min-width: 640px) {
        font-size: ${tokens.typography.fontSize.md};
        line-height: ${tokens.typography.lineHeight.sm};
        /* letter-spacing: ${tokens.typography.letterSpacing.display}; */
    }
`
export const Lead = (props) => {
    return(
        <ThemeContext.Consumer>
            {context => ( <$Lead theme={context.colorTheme}>{props.children}</$Lead> )}
        </ThemeContext.Consumer>
    );
}



const $Body = styled.div`
    /* border: 1px solid red; */
    font-family: ${tokens.typography.fontFamily.body};
    color: ${props => props.theme.colorTextSecondary};
    text-rendering: optimizeLegibility;
    font-size: ${tokens.typography.fontSize.xs};
    line-height: ${tokens.typography.lineHeight.md};
    
    @media (min-width: 640px) {
        font-size: ${tokens.typography.fontSize.md};
        line-height: ${tokens.typography.lineHeight.md};
    }
`

export const Body = (props) => {
    return(
        <ThemeContext.Consumer>
            {context => ( <$Body theme={context.colorTheme}>{props.children}</$Body> )}
        </ThemeContext.Consumer>
    );
}

const $hr = styled.hr`
    border: 0px;
    border-top: 1px solid ${props => props.theme.colorBorder};
    height: 1px;
    margin-top: ${tokens.space.xxxl};
    margin-bottom: ${tokens.space.sm};
`
export const Hr = (props) => {
    return(
        <ThemeContext.Consumer>
            {context => (  <$hr theme={context.colorTheme}>{props.children}</$hr> )}
        </ThemeContext.Consumer>
       
    );
}
