import React from 'react';

export const Age = () => {
    let today = new Date();
    let birthDate = new Date('09/09/1992');
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return (
        <span>{age}</span>
    );
}

export const Since = (props) => {

    // this function is used to display how many years and months
    // have elapsed since beginning of a new job
    // 
    // note: it is a very simplified time calculation 

    let today = new Date();
    let date = new Date(props.date);

    var diff = Math.floor(today.getTime() - date.getTime());
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff/day);
    var months = Math.floor(days/30);
    var years = Math.floor(months/12);

    var message;

    if ( years >= 1 ){
        if (years == 1){
            // since a year
            message = years + " year";
        }else{
            // since 2 years 
            message = years + " years";
        }
        if (months%12 >=1 ){
            if( months%12 == 1 ){
                message += ' and ' + + months%12 + ' month';
            }else{
                // since 2 years and 6 months
                message += ' and ' + months%12 + ' months';
            }
        }
    } 
    else if ( months >= 2){
        // since 2 months
        message = months + ' months';
    } else if ( months >= 1){
        // since 1 months
        message = ' month';
    } else if ( days >= 1){
        // since 30 days
        message = days + ' days';
    } else{
        // just some fallback
        message = " time";
    }

    return(
        <span>{message}</span>
    );
}
