import React, { useState, useEffect } from 'react'
import useDarkMode from 'use-dark-mode'
import styled from 'styled-components'
import { ThemeContext } from "../tokens/theme"
import * as tokens from "../tokens/tokens"


const $Toggle = styled.div`
    display: inline-block;
    align-items: center;

    .theme-switch {
        display: inline-block;
        height: ${tokens.space.lg};
        position: relative;
        width: ${tokens.space.xxl};
    }

    .theme-switch input {
        display:none;
    }

    .slider {
        background-color: ${props=>props.theme.colorBorder};
        bottom: 0;
        cursor: pointer;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .4s;
    }

    .slider:before {
        background-color: ${props=>props.theme.colorBackground};
        bottom: ${tokens.space.xs};
        content: "";
        height: ${tokens.space.md};
        width: ${tokens.space.md};
        left: ${tokens.space.xs};;
        position: absolute;
        transition: .4s;
    }

    /* input:checked + .slider {
        background-color: #66bb6a;
    } */

    input:checked + .slider:before {
        transform: translateX( ${tokens.space.md} );
    }

    .slider.round {
        border-radius: ${tokens.space.xl};
    }

    .slider.round:before {
        border-radius: 50%;
    }

`



const DarkModeToggle = (props) => {
  const darkMode = useDarkMode(false);

  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return (
    <ThemeContext.Consumer>
    { context => (

        <$Toggle theme={context.colorTheme}>
            <label class="theme-switch" for="checkbox">
                <input type="checkbox" id="checkbox" value={darkMode.value} onChange={darkMode.toggle}/>
                <div class="slider round"></div>
            </label>
        </$Toggle>

    )}
    </ThemeContext.Consumer>
  );
};

export default DarkModeToggle;
